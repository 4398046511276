// // "use client";

// import React from "react";
// import { Col, Container, Image, Row } from "react-bootstrap";
// import { Icon } from "@iconify/react";
// import Slider from "react-slick";
// import "./OurServices.css";

// function OurServices() {
//   var ClientLogo = {
//     dots: false,
//     navs: false,
//     infinite: true,
//     autoplay: true,
//     autoplaySpeed: 4000,
//     slidesToShow: 6,
//     slidesToScroll: 1,
//     responsive: [
//       {
//         breakpoint: 1200,
//         settings: {
//           slidesToShow: 6,
//           slidesToScroll: 1,
//         },
//       },
//       {
//         breakpoint: 991,
//         settings: {
//           slidesToShow: 6,
//           slidesToScroll: 1,
//         },
//       },
//       {
//         breakpoint: 767,
//         settings: {
//           slidesToShow: 6,
//           slidesToScroll: 1,
//         },
//       },
//       {
//         breakpoint: 480,
//         settings: {
//           slidesToShow: 6,
//           slidesToScroll: 1,
//         },
//       },
//     ],
//   };
//   return (
//     <div className="bg-white clientLogosHidden mt-8">
//       <Container>
//         <Slider className="client-logosMobile client-logos" {...ClientLogo}>
//           <div className="client-logos-Items">
//             <Image
//               src="/assets/images/gsa.webp"
//               alt="client-logo1"
//               width={100}
//               height={50}
//               priority
//             />
//           </div>
//           <div className="client-logos-Items">
//             <Image
//               src="/assets/images/udh.webp"
//               alt="client-logo2"
//               width={100}
//               height={50}
//               priority
//             />
//           </div>
//           <div className="client-logos-Items">
//             <Image
//               src="/assets/images/ubp.webp"
//               alt="client-logo2"
//               width={100}
//               height={50}
//               priority
//             />
//           </div>
//           <div className="client-logos-Items">
//             <Image
//               src="/assets/images/metro.webp"
//               alt="client-logo3"
//               width={100}
//               height={50}
//               priority
//             />
//           </div>
//           <div className="client-logos-Items">
//             <Image
//               src="/assets/images/client-logo-02.webp"
//               alt="client-logo4"
//               width={100}
//               height={50}
//               priority
//             />
//           </div>
//           <div className="client-logos-Items">
//             <Image
//               src="/assets/images/client-logo-03.webp"
//               alt="client-logo5"
//               width={100}
//               height={50}
//               priority
//             />
//           </div>
//           <div className="client-logos-Items">
//             <Image
//               src="/assets/images/client-logo-04.webp"
//               alt="client-logo6"
//               width={100}
//               height={50}
//               priority
//             />
//           </div>
//           <div className="client-logos-Items">
//             <Image
//               src="/assets/images/client-logo-06.webp"
//               alt="client-logo7"
//               width={100}
//               height={50}
//               priority
//             />
//           </div>
//           <div className="client-logos-Items">
//             <Image
//               src="/assets/images/client-logo-07.webp"
//               alt="client-logo8"
//               width={100}
//               height={50}
//               priority
//             />
//           </div>
//           <div className="client-logos-Items">
//             <Image
//               src="/assets/images/client-logo-08.webp"
//               alt="client-logo9"
//               width={100}
//               height={50}
//               priority
//             />
//           </div>
//           <div className="client-logos-Items">
//             <Image
//               src="/assets/images/client-logo-09.webp"
//               alt="client-logo10"
//               width={100}
//               height={50}
//               priority
//             />
//           </div>
//           <div className="client-logos-Items">
//             <Image
//               src="/assets/images/client-logo-10.webp"
//               alt="client-logo11"
//               width={100}
//               height={50}
//               priority
//             />
//           </div>
//           <div className="client-logos-Items">
//             <Image
//               src="/assets/images/client-logo-11.webp"
//               alt="client-logo12"
//               width={100}
//               height={50}
//               priority
//             />
//           </div>
//           <div className="client-logos-Items">
//             <Image
//               src="/assets/images/client-logo-12.svg"
//               alt="client-logo13"
//               width={100}
//               height={50}
//               priority
//             />
//           </div>
//           <div className="client-logos-Items">
//             <Image
//               src="/assets/images/client-logo-13.webp"
//               alt="client-logo14"
//               width={100}
//               height={50}
//               priority
//             />
//           </div>
//         </Slider>
//       </Container>
//       <div className="OurServices-Section">
//         <Container>
//           <h2>Our Services</h2>
//           <Row>
//             <Col lg={4} md={6}>
//               <div className="service-box">
//                 <h6>Client Asset Management</h6>
//                 <span className="s-card-text">
//                   We will buy, sell, trade, manage, warehouse, & recycle your
//                   surplus business and industrial assets. These assets can be
//                   anything of value, tangible or non-tangible, pertaining to any
//                   business including inventory, fixed assets, intellectual
//                   property, web sites or the business itself.
//                 </span>
//                 <a
//                   aria-label="Learn More about Client Asset Management"
//                   href="/client-asset-management"
//                 >
//                   Learn More <Icon icon="material-symbols:arrow-right-alt" />
//                 </a>
//               </div>
//             </Col>
//             <Col lg={4} md={6}>
//               <div className="service-box">
//                 <h6>Surplus Inventory</h6>
//                 <span className="s-card-text">
//                   We have surplus inventory available for almost every business
//                   need, at any scale, and if we don’t have it, We can get it!
//                   Who we are, our experience, our relationships and systems are
//                   completely custom and built to scale. You can count on us to
//                   provide quality surplus, facilitating cost savings to your
//                   bottom line, or we can locate those hard to find items you
//                   need!
//                 </span>
//                 <a aria-label="Learn More about Inventory" href="/Inventory">
//                   Learn More <Icon icon="material-symbols:arrow-right-alt" />
//                 </a>
//               </div>
//             </Col>
//             <Col lg={4} md={6}>
//               <div className="service-box">
//                 <h6>Recycling Services</h6>
//                 <span className="s-card-text">
//                   Have unique scrap recycling needs? We can manage all of it.
//                   From superalloys, titanium alloys, down to iron and cardboard.
//                   We can manage the full lifecycle of your scrap from shipment
//                   at your facilities to processing, handling, quality control
//                   and compliance.
//                 </span>
//                 <a
//                   aria-label="Learn More about Recycling Services"
//                   href="/recycling-services"
//                 >
//                   Learn More
//                   <Icon icon="material-symbols:arrow-right-alt" />
//                 </a>
//               </div>
//             </Col>
//             <Col lg={4} md={6}>
//               <div className="service-box">
//                 <h6>Finders Fee Program</h6>
//                 <span className="s-card-text">
//                   A distribution relationship, or finders fee could be through
//                   an individual or a program. Become a "finder" and located
//                   fixed assets for us to buy and earn a commission
//                 </span>
//                 <a
//                   aria-label="Learn More about Finders Program"
//                   href="/finders-program"
//                 >
//                   Learn More
//                   <Icon icon="material-symbols:arrow-right-alt" />
//                 </a>
//               </div>
//             </Col>
//             <Col lg={4} md={6}>
//               <div className="service-box">
//                 <h6>Sell to Us</h6>
//                 <span className="s-card-text">
//                   Every business has surplus assets. Those machines on your back
//                   dock, or in the spare warehouse could be a substantial help to
//                   your bottom line. Call us for new and innovative ways to
//                   maximize your capital recovery from your surplus!
//                 </span>
//                 <a
//                   aria-label="Learn More about Selling to Us"
//                   href="/sell-to-us"
//                 >
//                   Learn More
//                   <Icon icon="material-symbols:arrow-right-alt" />
//                 </a>
//               </div>
//             </Col>
//             <Col lg={4} md={6}>
//               <div className="service-box">
//                 <h6>Specialized Contracts</h6>
//                 <span className="s-card-text">
//                   We pride ourselves on being one of the most agile teams in the
//                   Industry. We can build and manage the process itself, the
//                   software, or even provide the staffing. Designed based on your
//                   needs as a company, irrelevant of Industry. We are
//                   professionals in process design and business management.
//                 </span>
//                 <a
//                   aria-label="Learn More about Specialized Contracts"
//                   href="/specialized-contracts"
//                 >
//                   Learn More
//                   <Icon icon="material-symbols:arrow-right-alt" />
//                 </a>
//               </div>
//             </Col>
//           </Row>
//         </Container>
//       </div>
//     </div>
//   );
// }

// export default OurServices;

"use client";

import React, { useMemo } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Image from "next/image";
import { Icon } from "@iconify/react";
import dynamic from "next/dynamic";
import "./OurServices.css";

const Slider = dynamic(() => import("react-slick"), {
  ssr: false,
  loading: () => <div className="slider-placeholder"></div>,
});

const ServiceBox = React.memo(({ title, description, link, linkText }) => (
  <div className="service-box">
    <h6>{title}</h6>
    <span className="s-card-text">{description}</span>
    <a href={link}>
      {linkText} <Icon icon="material-symbols:arrow-right-alt" />
    </a>
  </div>
));

function OurServices() {
  const ClientLogo = {
    dots: false,
    navs: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const clientLogos = useMemo(
    () => [
      {
        src: "https://kingsurplus-cdn.azureedge.net/fonts/gsa.webp",
        alt: "client-logo1",
      },
      {
        src: "https://kingsurplus-cdn.azureedge.net/fonts/udh.webp",
        alt: "client-logo2",
      },
      {
        src: "https://kingsurplus-cdn.azureedge.net/fonts/ubp.webp",
        alt: "client-logo2",
      },
      {
        src: "https://kingsurplus-cdn.azureedge.net/fonts/metro.webp",
        alt: "client-logo3",
      },
      {
        src: "https://kingsurplus-cdn.azureedge.net/fonts/client-logo-02.webp",
        alt: "client-logo4",
      },
      {
        src: "https://kingsurplus-cdn.azureedge.net/fonts/client-logo-03.webp",
        alt: "client-logo5",
      },
      {
        src: "https://kingsurplus-cdn.azureedge.net/fonts/client-logo-04.webp",
        alt: "client-logo6",
      },
      {
        src: "https://kingsurplus-cdn.azureedge.net/fonts/client-logo-06.webp",
        alt: "client-logo7",
      },
      {
        src: "https://kingsurplus-cdn.azureedge.net/fonts/client-logo-07.webp",
        alt: "client-logo8",
      },
      {
        src: "https://kingsurplus-cdn.azureedge.net/fonts/client-logo-08.webp",
        alt: "client-logo9",
      },
      {
        src: "https://kingsurplus-cdn.azureedge.net/fonts/client-logo-09.webp",
        alt: "client-logo10",
      },
      {
        src: "https://kingsurplus-cdn.azureedge.net/fonts/client-logo-10.webp",
        alt: "client-logo11",
      },
      {
        src: "https://kingsurplus-cdn.azureedge.net/fonts/client-logo-11.webp",
        alt: "client-logo12",
      },
      {
        src: "https://kingsurplus-cdn.azureedge.net/fonts/client-logo-12.svg",
        alt: "client-logo13",
      },
      {
        src: "http://kingsurplus-cdn.azureedge.net/fonts/client-logo-13.webp",
        alt: "client-logo14",
      },
    ],
    []
  );

  const services = useMemo(
    () => [
      {
        title: "Client Asset Management",
        description:
          "We will buy, sell, trade, manage, warehouse, & recycle your surplus business and industrial assets. These assets can be anything of value, tangible or non-tangible, pertaining to any business including inventory, fixed assets, intellectual property, web sites or the business itself.",
        link: "/client-asset-management",
        linkText: "Explore Asset Management Services",
      },
      {
        title: "Surplus Inventory",
        description:
          "We have surplus inventory available for almost every business need, at any scale, and if we don't have it, We can get it! Who we are, our experience, our relationships and systems are completely custom and built to scale. You can count on us to provide quality surplus, facilitating cost savings to your bottom line, or we can locate those hard to find items you need!",
        link: "/Inventory",
        linkText: "Browse Surplus Inventory",
      },
      {
        title: "Recycling Services",
        description:
          "Have unique scrap recycling needs? We can manage all of it. From superalloys, titanium alloys, down to iron and cardboard. We can manage the full lifecycle of your scrap from shipment at your facilities to processing, handling, quality control and compliance.",
        link: "/recycling-services",
        linkText: "View Recycling Solutions",
      },
      {
        title: "Finders Fee Program",
        description:
          'A distribution relationship, or finders fee could be through an individual or a program. Become a "finder" and located fixed assets for us to buy and earn a commission',
        link: "/finders-program",
        linkText: "Join Our Finders Program",
      },
      {
        title: "Sell to Us",
        description:
          "Every business has surplus assets. Those machines on your back dock, or in the spare warehouse could be a substantial help to your bottom line. Call us for new and innovative ways to maximize your capital recovery from your surplus!",
        link: "/sell-to-us",
        linkText: "Sell Your Surplus Assets",
      },
      {
        title: "Specialized Contracts",
        description:
          "We pride ourselves on being one of the most agile teams in the Industry. We can build and manage the process itself, the software, or even provide the staffing. Designed based on your needs as a company, irrelevant of Industry. We are professionals in process design and business management.",
        link: "/specialized-contracts",
        linkText: "Discover Specialized Contracts",
      },
    ],
    []
  );

  return (
    <div className="bg-white clientLogosHidden mt-8">
      <Container>
        <Slider className="client-logosMobile client-logos" {...ClientLogo}>
          {clientLogos.map((logo, index) => (
            <div key={index} className="client-logos-Items">
              <Image
                src={logo.src}
                alt={logo.alt}
                width={100}
                height={50}
                // priority={index < 3} // Only prioritize the first 3 images
                // loading={index >= 3 ? "lazy" : undefined}
                placeholder="blur"
                loading="lazy"
                blurDataURL="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwIiBoZWlnaHQ9IjUwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxyZWN0IHdpZHRoPSIxMDAiIGhlaWdodD0iNTAiIGZpbGw9IiNlZWVlZWUiLz48L3N2Zz4="
              />
            </div>
          ))}
        </Slider>
      </Container>
      <div className="OurServices-Section">
        <Container>
          <h2>Our Services</h2>
          <Row>
            {services.map((service, index) => (
              <Col key={index} lg={4} md={6}>
                <ServiceBox
                  title={service.title}
                  description={service.description}
                  link={service.link}
                  linkText={service.linkText}
                />
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default React.memo(OurServices);
