

import React, { useEffect, useState } from "react";
import axiosClient from "../../utils/axiosClient";
import { MenuItem, Select, FormControl, FormHelperText } from "@mui/material";
import { useRouter } from "next/router";
import "../Category/category.css";

function Category() {
  const router = useRouter();
  const { industry, categoryPage, slug } = router.query;
  const categorySlug = slug ? slug[0] || null : null;
  const industryValue = industry || null;
  const categoryPageValue = categoryPage || null;
  const [categoryModel, setCategoryModel] = useState({ data: [] });
  const [selectedCategory, setSelectedCategory] = useState("");
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    categoriesList();
  }, [industry, categoryPageValue]);

  const categoriesList = async () => {
    try {
      const url = industry
        ? `GetAllCategoryByIndustry/${industry}`
        : "GetAllCategory";
      const res = await axiosClient.get(url);
      if (res) {
        setCategoryModel({ data: res });
      } else {
        setCategoryModel({ data: [] });
      }
    } catch (error) {
      setCategoryModel({ data: [] });
    }
  };

  useEffect(() => {
    if (
      (categorySlug || categoryPageValue) &&
      categoryModel &&
      categoryModel.data.length > 0
    ) {
      const matchingVal = categoryModel.data.find(
        (item) =>
          item.slugName === categorySlug || item.slugName === categoryPageValue
      );
      if (matchingVal) {
        setSelectedCategory(matchingVal.id);
      } else {
        setSelectedCategory("");
      }
    }
  }, [categoryModel]);

  const handleClear = () => {
    setSelectedCategory("");
    if (industry) {
      router.push(industry);
    } else {
      router.push("/Inventory");
    }
  };

  const handleCategoryChange = (event) => {
    const newValue = event.target.value;
    const selectedOption = categoryModel.data.find(
      (item) => item.id === newValue
    );

    if (selectedOption) {
      setSelectedCategory(newValue);
      if (industryValue) {
        router.push(`/${industryValue}/${selectedOption.slugName}`);
      } else if (categoryPageValue) {
        router.push(`/category/${selectedOption.slugName}`);
      } else {
        router.push(`/category/${selectedOption.slugName}`);
      }
    } else {
      setSelectedCategory("");
      router.push("/Inventory");
    }
  };

  const capitalize = (str) => str.replace(/\b\w/g, (l) => l.toUpperCase());

  if (!isMounted) return null;

  return (
    <FormControl fullWidth variant="outlined">
      <Select
        value={selectedCategory}
        onChange={handleCategoryChange}
        displayEmpty
        fullWidth
        style={{
          backgroundColor: "#f5f5f5",
          borderRadius: "8px",
          height: "45px",
          borderColor: "#3f51b5",
          overflow: "hidden",
        }}
        MenuProps={{
          PaperProps: {
            style: {
              height: 300,

              width: 270,
              textWrap: "wrap",
            },
          },
          disableScrollLock: true,
        }}
        inputProps={{ "aria-label": "Select a Category" }}
      >
        <MenuItem value="">
          <span>Select a Category</span>
        </MenuItem>
        {categoryModel.data.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {capitalize(item.name)} ({item.itemCount})
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default Category;
