// import { Container } from "react-bootstrap";
// import dynamic from "next/dynamic";

// import Head from "next/head";
// // import HomeProductSection from "../components/HomeProductSection/HomeProductSection";
// import Header from "../components/Header/Header";
// import NewHomeBanner from "../components/NewHomeBanner/NewHomeBanner";
// import OurServices from "../components/OurServices/OurServices";
// // import ProjectCounter from "../components/ProjectCounter/ProjectCounter";
// // import Footer from "../components/Footer/Footer";
// import axiosClient from "../utils/axiosClient"; // Ensure axiosClient is configured correctly
// import "../app/globals.css";
// import "../../src/fonts/stylesheet.css";
// // Dynamically import the Footer component

// const HomeProductSection = dynamic(
//   () => import("../components/HomeProductSection/HomeProductSection"),
//   {
//     loading: () => <div className="loading-footer">Loading footer...</div>,
//     ssr: true,
//   }
// );

// const ProjectCounter = dynamic(
//   () => import("../components/ProjectCounter/ProjectCounter"),
//   {
//     loading: () => <div className="loading-footer">Loading footer...</div>,
//     ssr: false,
//   }
// );
// const Footer = dynamic(() => import("../components/Footer/Footer"), {
//   loading: () => <div className="loading-footer">Loading footer...</div>,
//   ssr: false, // Keep server-side rendering enabled for SEO
// });

// export default function Home({ industryData, recentProducts, bannerData }) {
//   return (
//     <>
//       <Header />
//       <Head>
//         <title>KSC | Home</title>
//         <meta
//           name="description"
//           content="Welcome to King Surplus - Your Source for Surplus Business and Industrial Assets"
//         />
//         <meta name="name" content="King Surplus" />
//         <meta property="og:type" content="website" />
//         <link rel="canonical" href="https://kingsurplus.com" />
//         <meta property="og:title" content="KingSurplus" />
//         <meta
//           property="og:description"
//           content="Welcome to King Surplus - Your Source for Surplus Business and Industrial Assets"
//         />
//         <meta
//           property="og:image"
//           content="https://kingsurplus.com/assets/images/logo.webp"
//         />
//         <meta property="og:type" content="website" />
//         <meta property="og:image:width" content="1200" />
//         <meta property="og:image:height" content="630" />
//         <meta property="og:image:type" content="image/png" />
//         <meta property="og:site_name" content="King Surplus" />
//         <meta property="og:locale" content="en_US" />
//         <meta property="og:locale:alternate" content="en_CA" />
//         <meta property="og:url" content="https://kingsurplus.com" />

//         <meta name="twitter:card" content="summary_large_image" />
//         <meta name="twitter:title" content="King Surplus" />
//         <meta
//           name="twitter:description"
//           content="Welcome to King Surplus - Your Source for Surplus Business and Industrial Assets"
//         />
//         <meta
//           name="twitter:image"
//           content="https://kingsurplus.com/assets/images/logo.webp"
//         />
//         <meta name="twitter:url" content="https://kingsurplus.com" />
//       </Head>
//       {/* <NewHomeBanner /> */}
//       <NewHomeBanner
//         industryData={bannerData.industryData}
//         categoryData={bannerData.categoryData}
//       />
//       <OurServices />
//       <Container className="mb-5">
//         <HomeProductSection
//           industryData={industryData}
//           recentProducts={recentProducts}
//         />
//       </Container>
//       <ProjectCounter />
//       <Footer />
//     </>
//   );
// }

// export async function getServerSideProps() {
//   let recentProducts = [];
//   let industryData = [];
//   let bannerData = { industryData: null, categoryData: null };

//   try {
//     const [
//       industryDataRes,
//       recentProductsRes,
//       bannerIndustryRes,
//       bannerCategoryRes,
//     ] = await Promise.all([
//       axiosClient.get("/GetAllIndustriesWithCategories"),
//       axiosClient.get("/GetRecentItems"),
//       axiosClient.get("/GetAllIndustry"),
//       axiosClient.get("/GetAllCategory"),
//     ]);

//     industryData = industryDataRes || [];
//     recentProducts = recentProductsRes || [];
//     bannerData.industryData = bannerIndustryRes || null;
//     bannerData.categoryData = bannerCategoryRes || null;
//   } catch (error) {
//     console.error("Error fetching data:", error);
//   }

//   return {
//     props: {
//       industryData,
//       recentProducts,
//       bannerData,
//     },
//   };
// }

import { Container } from "react-bootstrap";
import dynamic from "next/dynamic";
import Head from "next/head";
import axiosClient from "../utils/axiosClient";
import Header from "../components/Header/Header";
import NewHomeBanner from "../components/NewHomeBanner/NewHomeBanner";
import OurServices from "../components/OurServices/OurServices";
import "../app/globals.css";

// Dynamically Import Components (Optimize Loading)
const HomeProductSection = dynamic(
  () => import("../components/HomeProductSection/HomeProductSection"),
  { ssr: true }
);
const ProjectCounter = dynamic(
  () => import("../components/ProjectCounter/ProjectCounter"),
  { ssr: false, loading: () => <p>Loading counter...</p> }
);
const Footer = dynamic(() => import("../components/Footer/Footer"), {
  ssr: false,
  loading: () => <p>Loading footer...</p>,
});

export default function Home({ industryData, recentProducts, bannerData }) {
  return (
    <>
      <Head>
        <title>KSC | Home</title>
        <meta
          name="description"
          content="Welcome to King Surplus - Your Source for Surplus Business and Industrial Assets"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://kingsurplus.com" />
      </Head>
      <Header />
      <NewHomeBanner
        industryData={bannerData.industryData}
        categoryData={bannerData.categoryData}
      />
      <OurServices />
      <Container className="mb-5">
        <HomeProductSection
          industryData={industryData}
          recentProducts={recentProducts}
        />
      </Container>
      <ProjectCounter />
      <Footer />
    </>
  );
}

// Implement Incremental Static Regeneration (ISR)
export async function getStaticProps() {
  let industryData = [];
  let recentProducts = [];
  let bannerData = { industryData: null, categoryData: null };

  try {
    // Fetching all the required data
    const [
      industryDataRes,
      recentProductsRes,
      bannerIndustryRes,
      bannerCategoryRes,
    ] = await Promise.all([
      axiosClient.get("/GetAllIndustriesWithCategories"),
      axiosClient.get("/GetRecentItems"),
      axiosClient.get("/GetAllIndustry"),
      axiosClient.get("/GetAllCategory"),
    ]);

    // Assigning data from API responses
    industryData = industryDataRes || [];
    recentProducts = recentProductsRes || [];
    bannerData.industryData = bannerIndustryRes?.data || null;
    bannerData.categoryData = bannerCategoryRes?.data || null;
  } catch (error) {
    console.error("Error fetching data:", error);
  }

  return {
    props: {
      industryData,
      recentProducts,
      bannerData,
    },
    revalidate: 60, // Page will revalidate every 60 seconds in the background
  };
}
