import React, { useEffect, useState } from "react";
import axiosClient from "../../utils/axiosClient";
import { MenuItem, Select, FormControl, FormHelperText } from "@mui/material";
import { useRouter } from "next/router";
import { useAppDispatch } from "@/lib/store/hooks";
import { clearFilters } from "@/lib/store/Slices/FilterSlice";

const IndustrySelect = () => {
  const [industries, setIndustries] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState("");
  const router = useRouter();
  const dispatch = useAppDispatch();
  const { industry } = router.query; // Get industry from query params

  useEffect(() => {
    const fetchIndustries = async () => {
      try {
        const response = await axiosClient.get("GetAllIndustry");
        setIndustries(response || []);
      } catch {
        setIndustries([]);
      }
    };

    fetchIndustries();
  }, []); // Run only once on component mount

  useEffect(() => {
    if (industry && industries.length > 0) {
      // Find matching industry based on slugName for better SEO
      const matchingIndustry = industries.find(
        (item) => item.slugName === industry
      );
      if (matchingIndustry) {
        setSelectedIndustry(matchingIndustry.id);
      }
    }
  }, [industries, industry]); // Run when industries or query param changes

  const handleIndustryChange = (event) => {
    const selectedValue = event.target.value;
    const newValue = industries.find((item) => item.id === selectedValue);
    if (newValue) {
      setSelectedIndustry(newValue.id);
      dispatch(clearFilters()); // Uncomment if needed
      router.push(`/${newValue.slugName}`);
    } else {
      setSelectedIndustry("");
      router.push("/Inventory");
    }
  };

  const handleClear = () => {
    setSelectedIndustry("");
    dispatch(clearFilters()); // Uncomment if needed
    router.push("/Inventory");
  };

  const capitalize = (str) => str.replace(/\b\w/g, (l) => l.toUpperCase());

  return (
    <FormControl fullWidth variant="outlined">
      <Select
        value={selectedIndustry}
        onChange={handleIndustryChange}
        displayEmpty
        fullWidth
        style={{
          backgroundColor: "#f5f5f5",
          borderRadius: "8px",
          height: "45px",
          overflow: "hidden",
        }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 300,
              width: 270,
              zIndex: 1300,
            },
          },
          disableScrollLock: true,
        }}
        inputProps={{ "aria-label": "Select an Industry" }}
        renderValue={(selected) =>
          selected
            ? industries.find((item) => item.id === selected)?.name
            : "Select an Industry"
        }
      >
        <MenuItem value="">
          <span>Select an Industry</span>
        </MenuItem>
        {industries.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {capitalize(item.name)} ({item.itemCount})
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default IndustrySelect;
